.main-search-box {
  max-width: 600px;
}

.docs-overview {
  .card {
    border-color: $theme-divider;
    position: relative;
    height: 100%;
    &:hover {
      box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15)!important;
      background: $theme-bg-light;
      .card-icon-holder {
        background: #fff;
      }
    }
  }
  .card-icon-holder {
    width: 60px;
    height: 60px;
    font-size: 1.5rem;
    padding-top: 1rem;
    display: inline-block;
  }
  .card-title-text {
    display: inline-block;
    position: relative;
    top: -0.3rem;
  }
  .card-link-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

}


/* Docs page */
.search-form {
  position: relative;
  width: 100%;
  .search-input {
    font-size: 0.875rem;
    @include border-radius(1.5rem);
    padding-right: 3rem;
    padding-left: 1.5rem;
    &:focus {
      border-color: $theme-divider;
    }
  }
  .search-btn {
    color: lighten($theme-text-color-secondary, 15%);
    background: none;
    border: none;
    position: absolute;
    right: 0;
    top: 0.15rem;
    margin-right: 0;


    &:active, &:focus, &:hover {
      outline: none !important;
      color: $theme-color-primary;
      box-shadow: none;

    }

  }
}


.docs-branding {
  min-height: 69px;
  .top-search-box {
    width: 300px;
  }
}

.docs-logo-wrapper {
  position: absolute;
  left: 15px;
  top: 0.5rem;

  .site-logo {
    display: inline-block;
  }
}

.docs-top-utilities {
  padding-top: 4px;
}

.docs-sidebar-toggler {
  display: inline-block;
  border: none;
  padding: 0;
  font-size: inherit;
  position: relative;
  top: 0.3rem;
  background: none;
  &:focus {
    outline: none;
  }
  &:hover {
    span {
      opacity: 1;
    }
  }
  span {
    display: block;
    background-color: $theme-text-color-primary;
    height: 3px;
    width: 24px;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
    left: 0;
    opacity: 0.8;
    @include border-radius(1px);
  }
}

.docs-wrapper {
  position: relative;

}

.docs-sidebar {
  display: none;
  width: 300px;
  position: fixed;
  z-index: 10;
  top: 69px;
  height: calc(100% - 69px);
  background: #fff;
  overflow-y:auto;
  /* Forefox scrollbar */
  scrollbar-color: rgba(0, 0, 0, 0.2) $smoky-white;;
  scrollbar-width: thin;

  /* Chrome webkit scrollbar */

  &::-webkit-scrollbar {
    width: 6px;
    background-color: $smoky-white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.125);
    @include border-radius(0.5rem);
  }

  @include transition(transform .35s ease-in-out);
  box-shadow: 1px 0 5px 0 rgba(0,0,0,.1);
  &.sidebar-visible {
    @include transform(translateX(0));
    display: block;
  }
  &.sidebar-hidden {
    @include transform(translateX(-300px));
    display: block;
  }
}

.docs-nav {
  overflow-y: auto;
  padding: 1rem;

  .section-title {
    font-size: 1rem;
    margin-bottom: 0;
    a {
      &:hover {
        text-decoration: none;
      }
    }

  }
  .section-items {
    font-size: 0.875rem;
    position: relative;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
  .nav-item {
    margin-left: 1rem;
    &.section-title {
      margin-left: 0;
      .nav-link {
        padding: 0;
        &:before {
          display: none;
        }
      }
    }
    &.active .nav-link{
      color: $theme-color-primary;
      &:before {
        background-color: $theme-color-primary;
      }
      .theme-icon-holder {
        color: #fff;
        background: $theme-color-primary;
      }
    }
  }
  .nav-link {
    display: inline-block;
    position: relative;
    padding: 0.5rem 1rem;
    color: $theme-text-color-secondary;
    .theme-icon-holder {
      font-size: 0.875rem;
      padding-top: 0.375rem;
    }
    &.active {
      color: $theme-color-primary;
      &:before {
        background-color: $theme-color-primary;
      }
      .theme-icon-holder {
        color: #fff;
        background: $theme-color-primary;
      }
    }
    &:before {
      background-color: darken($theme-bg-light, 10%);
      content: ' ';
      display: inline-block;
      height: inherit;
      left: 0;
      margin-top: -0.5rem;
      position: absolute;
      width: 3px;
      height: 100%;
      @include border-radius(1rem);
    }
  }
  .nav-item .nav-item .nav-link {
    &:before {
      background-color: $nav-secondary-line;
      width: 2px;
    }
  }
}



.docs-content {
  margin-left: 300px;
  img {
    max-width: 90vw;
  }
}


.docs-article {
  padding: 2rem;
  color: $theme-text-color-secondary;
  scroll-margin-top: 69px; //height of the sticky header
  scroll-snap-margin-top: 69px; //Safari 
  strong {
    color: $theme-text-color-primary;
  }
  .docs-heading {
    font-size: 3rem;
    margin-bottom: 2rem;
    position: relative;

  }

  .docs-time {
    position: absolute;
    display:inline-block;
    right: 0;
    bottom: 1rem;
    font-size: 0.75rem;
    font-weight: normal;
    color: lighten($theme-text-color-secondary, 10%);
  }

  h2 {
    color: darken($theme-text-color-secondary, 10%);
  }

  .docs-section {
    padding: 2rem 0;
    border-bottom: 1px solid $theme-divider;
    scroll-margin-top: 69px; //height of the sticky header
    scroll-snap-margin-top: 69px; //Safari 
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
    .h2 {
      font-size: 1.875rem;
      margin-bottom: 1rem;
      color: $theme-text-color-primary
    }
  }

  h3 {
    font-size: 1.5rem;
    color: darken($theme-text-color-secondary, 10%);
  }
  h4 {
    font-size: 1.25rem;
    color: darken($theme-text-color-secondary, 10%);
  }
  h5 {
    font-size: 1rem;
    color: darken($theme-text-color-secondary, 10%);
  }
  h6 {
    font-size: 0.875rem;
    color: darken($theme-text-color-secondary, 10%);
  }
  ul, ol, dl {
    padding-left: 1.5rem;
  }
  li {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}


.docs-code-block {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.callout-block {
  padding: 1.5rem;
  border-left: 3px solid $theme-text-color-secondary;
  background: $smoky-white;
  margin-top: 1rem;
  margin-bottom: 1rem;

  code {
    background: #fff;
  }

  .callout-title {
    font-size: 1rem;
  }

  &.callout-block-info {
    border-color: $theme-info-color;
    background: lighten($theme-info-color, 35%);
    .callout-title {
      color: darken($theme-info-color, 15%);
    }
    a {
      color: darken($theme-info-color, 15%);
    }
  }
  &.callout-block-success {
    border-color: $theme-success-color;
    background: lighten($theme-success-color, 40%);
    .callout-title {
      color: darken($theme-success-color, 15%);
    }
    a {
      color: darken($theme-success-color, 15%);
    }
  }
  &.callout-block-warning {
    border-color: $theme-warning-color;
    background: lighten($theme-warning-color, 35%);
    .callout-title {
      color: darken($theme-warning-color, 15%);
    }
    a {
      color: darken($theme-warning-color, 15%);
    }
  }
  &.callout-block-danger {
    border-color: $theme-danger-color;
    background: lighten($theme-danger-color, 35%);
    .callout-title {
      color: darken($theme-danger-color, 15%);
    }
    a {
      color: darken($theme-danger-color, 15%);
    }
  }
}

.cta-section {
  .container {
    position: relative;
    z-index: 10;
  }

}


/* printing  */
@media print {
  #docs-sidebar {
    display: none !important;
  }
}



//// Small devices (landscape phones, 576px and up)
//@media (min-width: 576px) {  }
//
//// Medium devices (tablets, 768px and up)
//@media (min-width: 768px) {
//
//}
//
//// Large devices (desktops, 992px and up)
//@media (min-width: 992px) {
//
//}
//
//// Extra large devices (large desktops, 1200px and up)
//@media (min-width: 1200px) {
//
//}
//
//
///* We occasionally use media queries that go in the other direction */
//// Extra small devices (portrait phones, less than 576px)
//@media (max-width: 575.98px) {
//
//}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .docs-article {
    padding-left: 0;
    padding-right: 0;
    .docs-heading {
      font-size: 2.25rem;
    }

    .section-heading {
      font-size: 1.5rem;
    }
  }
}

//// Medium devices (tablets, less than 992px)
//@media (max-width: 991.98px) {
//
//
//}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .docs-content {
    margin-left: 0;
  }
  .docs-article .docs-time {
    position: static;
    display: block;
    padding-top: 0.5rem;
  }
}